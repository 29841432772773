import {createSlice} from "@reduxjs/toolkit";
import {AEMetadata} from "../../../../_emywork/AEPagination";
import {getConfig} from "../../../../_metronic/i18n";
import {localField} from "../../../../_emywork/helpers/UIHelper";

export const actionTypes = {
  List:"list",
  Delete:"deleteDialog",
  Edit:"editForm",
  Details:"editForm",

}


export const defaultEntity = {
  id: undefined,
  attachments: [],
  project_id: undefined,
  price: 0,
  note: "",
  user_id: undefined,
  status: 'PENDING'
}


export const slice = createSlice({
  name: 'project_proposals',
  initialState: {
    [actionTypes.List]:{
      entities: [],
      error: null,
      isLoading: false,
      meta: new AEMetadata({perPage:5},{ }, {},['*','created_by']),
    },
    [actionTypes.Delete]:{
      entity: defaultEntity,
      error: null,
      isLoading: false,
    },

    [actionTypes.Edit]:{
      entity: defaultEntity,
      validationErrors: [],
      isLoading: false,
      error: null,
    },

    [actionTypes.Details]:{
      entity: defaultEntity,
      isLoading: false,
      error: null,
    },
  },
  reducers: {
    startCall: (state, action) => {
      const { type, meta } = action.payload;
      if(!type in actionTypes) return;

      state[type].error = null;
      state[type].isLoading = true;

      if([actionTypes.Edit].includes(type)){
        state[type].validationErrors = [];
      }

      // if([actionTypes.List].includes(type)){
      //   state[type].meta = meta;
      // }
    },
    endCall: (state, action) => {
      const { error, type, entity } = action.payload;

      if(!type in actionTypes) return;

      state[type].error = error;
      state[type].isLoading = false;

      if([actionTypes.Edit].includes(type)){
        state[type].validationErrors = [];
      }


    },
    catchError: (state, action) => {
      const { data, type } = action.payload;
      if(!type in actionTypes) return;

      state[type].error = true;
      state[type].isLoading = false;

      if([actionTypes.Edit].includes(type)){
        state[type].validationErrors = data;
      }


    },
    fetched: (state, action) => {
      const { type } = action.payload;
      if(!type in actionTypes) return;

      state[type].error = null;
      state[type].isLoading = false;

      if([actionTypes.Edit, actionTypes.Delete, actionTypes.Details].includes(type)){
        const { entity } = action.payload;
        state[type].entity = entity;
        if([actionTypes.Edit].includes(type)){
          state[type].validationErrors = [];
        }
      }

      if([actionTypes.List].includes(type)){
        const { entities } = action.payload;
        state[type].entities = entities;
        if([actionTypes.List].includes(type)){
          if(action.payload.meta){
            const { meta: { pagination: { total, pages } } } = action.payload;
            state[type].meta.pagination.total = total;
            state[type].meta.pagination.pages = pages;
          }
        }
      }

    },
  }
});
