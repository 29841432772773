import faker from "faker";
import {API_UPLOAD_PATH} from "../../../../_emywork/helpers/UIHelper";

export const CONTEXT = "emy_services";
export const UPLOAD_PATH = `${API_UPLOAD_PATH}/${CONTEXT}`

export const CONTEXT_TITLE = {
  singular: "EMY_SERVICE",
  plural: "EMY_SERVICES",
}
export const defaultEntity = process.env.NODE_ENV === "development" ?
  {
    "title": faker.lorem.words(),
    "description": faker.lorem.paragraphs(),
    "price": faker.random.float(),
    "delivery_time": faker.random.number(),
    "response_time": faker.random.number(),
    "location_id": undefined,
    "subcategories": [],
    "skills": [],
    "images": []
  } :
  {
    "title": '',
    "description": '',
    "price": 0,
    "delivery_time": 0,
    "response_time": 0,
    "location_id": undefined,
    "subcategories": [],
    "skills": [],
    "images": []
  }