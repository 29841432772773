import faker from "faker";
import {API_UPLOAD_PATH} from "../../../../_emywork/helpers/UIHelper";

export const CONTEXT = "proposals";
export const UPLOAD_PATH = `${API_UPLOAD_PATH}/${CONTEXT}`

export const CONTEXT_TITLE = {
  singular: "PROPOSAL",
  plural: "PROPOSALS",
}
export const defaultEntity = process.env.NODE_ENV === "development" ?
  {
    "proposalname": "aelfannir",
    "email": "aelfannir@gmail.com",
    "phone": "+212 690922699",
    "roles": [
      "ROLE_PROVIDER",
      "ROLE_CLIENT",
      "ROLE_SUPER_ADMIN"
    ],
    "commercial_name": "Elfannir Abderrahmane",
    "email_verified_at": "2021-01-12T08:57:19.000000Z",
    "email_verify_token": null,
    "about": null,
    "badge": null,
    "created_at": null,
    "updated_at": null,
    "legal_form_id": 1,
    "activity_area_id": null,
    "location_id": 343,
    "function_id": null,

    "skills": [],
    "experiences": [],
    "educations": [],
    "awards": [],
    "feedbacks": [],
    "emy_projects": [],
    "emy_products": [],
    "emy_services": [],
  } :
  {
    "title": '',
    "description": '',
    "price": 0,
    "delivery_time": 0,
    "response_time": 0,
    "location_id": undefined,
    "subcategories": [],
    "skills": [],
    "images": [],
    "experiences": [],
    "educations": [],
    "awards": [],
    "feedbacks": [],
    "emy_projects": [],
    "emy_products": [],
    "emy_services": [],
  }