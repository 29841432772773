import {createSlice} from "@reduxjs/toolkit";
import {AEMetadata} from "../../../../_emywork/AEPagination";
import {DateFormat} from "../../../../_emywork/helpers/momentHelper";
import moment from "moment";

export const actionTypes = {
  List:"list",
  Delete:"deleteDialog",
  Edit:"editForm",
  Details:"Details",
}

const today = moment().format(DateFormat);
export const defaultEntity = process.env.NODE_ENV === "development" ?
  {
    "title": "Event test",
    "description": "Tempora et neque aspernatur quos facilis accusamus dolorem. Reiciendis non sed sit numquam iure. Incidunt exercitationem repellendus aut quaerat id corrupti ullam.",
    "start_at": today,
    "end_at": today,
    "user_id": undefined
  } :
  {
    "title": '',
    "description": '',
    "start_at": today,
    "end_at": today,
    "user_id": undefined
  }

export const slice = createSlice({
  name: 'calendar_events',
  initialState: {
    [actionTypes.List]:{
      entities: [],
      error: null,
      isLoading: false,
      meta: new AEMetadata({perPage:10}, {field:'last_message', asc: false}, {},['*']),
    },

    [actionTypes.Delete]:{
      entity: defaultEntity,
      error: null,
      isLoading: false,
    },

    [actionTypes.Edit]:{
      entity: defaultEntity,
      validationErrors: [],
      isLoading: false,
      error: null,
    },

    [actionTypes.Details]:{
      entity: defaultEntity,
      validationErrors: [],
      isLoading: false,
      error: null,
    },

  },
  reducers: {
    startCall: (state, action) => {
      const { type, meta } = action.payload;
      if(!type in actionTypes) return;

      state[type].error = null;
      state[type].isLoading = true;

      if([actionTypes.Edit, actionTypes.Details].includes(type)){
        state[type].validationErrors = [];
      }

      // if([actionTypes.List].includes(type)){
      //   state[type].meta = meta;
      // }
    },
    endCall: (state, action) => {
      const { error, type, entity } = action.payload;

      if(!type in actionTypes) return;

      state[type].error = error;
      state[type].isLoading = false;

      if([actionTypes.Edit, actionTypes.Details].includes(type)){
        state[type].validationErrors = [];
      }


    },
    catchError: (state, action) => {
      const { data, type } = action.payload;
      if(!type in actionTypes) return;

      state[type].error = true;
      state[type].isLoading = false;

      if([actionTypes.Edit, actionTypes.Details].includes(type)){
        state[type].validationErrors = data;
      }


    },
    fetched: (state, action) => {
      const { type } = action.payload;
      if(!type in actionTypes) return;

      state[type].error = null;
      state[type].isLoading = false;

      if([actionTypes.Edit, actionTypes.Delete, actionTypes.Details].includes(type)){
        const { entity } = action.payload;
        state[type].entity = entity;
        if([actionTypes.Edit, actionTypes.Details].includes(type)){
          state[type].validationErrors = [];
        }
      }

      if([actionTypes.List].includes(type)){
        const { entities } = action.payload;
        state[type].entities = entities;
        if([actionTypes.List].includes(type)){
          if(action.payload.meta){
            const { meta: { pagination: { total, pages } } } = action.payload;
            state[type].meta.pagination.total = total;
            state[type].meta.pagination.pages = pages;
          }
        }
      }

    },
  }
});
