import {createSlice} from "@reduxjs/toolkit";
import {AEMetadata} from "../../../../../_emywork/AEPagination";
import {getConfig} from "../../../../../_metronic/i18n";
import {localField} from "../../../../../_emywork/helpers/UIHelper";

export const actionTypes = {
  List:"list",
  Delete:"deleteDialog",
  Edit:"editForm",
}


export const defaultEntity = process.env.NODE_ENV === "development" ?
  {
    "id": undefined,
    "title": "",
    "achieved_at": ""
  } :
  {
    "id": undefined,
    "title": "",
    "achieved_at": ""
  }
const {selectedLang} = getConfig()



export const slice = createSlice({
  name: 'user_awards',
  initialState: {
    [actionTypes.List]:{
      entities: [],
      error: null,
      isLoading: false,
      meta: new AEMetadata({perPage:5},{ }, {},['*']),
    },
    [actionTypes.Delete]:{
      entity: defaultEntity,
      error: null,
      isLoading: false,
    },

    [actionTypes.Edit]:{
      entity: defaultEntity,
      validationErrors: [],
      isLoading: false,
      error: null,
    },

    [actionTypes.LocationFilterList]:{
      entities: [],
      error: null,
      isLoading: false,
      meta: new AEMetadata({perPage:1000},{ field:`name_${selectedLang}` }, {},['id',localField('name')]),
    },

    [actionTypes.LocationFormList]:{
      entities: [],
      error: null,
      isLoading: false,
      meta: new AEMetadata({perPage:1000},{ field:`name_${selectedLang}` }, {},['id',localField('name')]),
    },

    [actionTypes.UserFilter]:{
      entities: [],
      error: null,
      isLoading: false,
      meta: new AEMetadata({perPage:1000},{ field:`name_${selectedLang}` }, {},['id',localField('name')]),
    },
  },
  reducers: {
    startCall: (state, action) => {
      const { type, meta } = action.payload;
      if(!type in actionTypes) return;

      state[type].error = null;
      state[type].isLoading = true;

      if([actionTypes.Edit].includes(type)){
        state[type].validationErrors = [];
      }

      // if([actionTypes.List].includes(type)){
      //   state[type].meta = meta;
      // }
    },
    endCall: (state, action) => {
      const { error, type, entity } = action.payload;

      if(!type in actionTypes) return;

      state[type].error = error;
      state[type].isLoading = false;

      if([actionTypes.Edit].includes(type)){
        state[type].validationErrors = [];
      }


    },
    catchError: (state, action) => {
      const { data, type } = action.payload;
      if(!type in actionTypes) return;

      state[type].error = true;
      state[type].isLoading = false;

      if([actionTypes.Edit].includes(type)){
        state[type].validationErrors = data;
      }


    },
    fetched: (state, action) => {
      const { type } = action.payload;
      if(!type in actionTypes) return;

      state[type].error = null;
      state[type].isLoading = false;

      if([actionTypes.Edit, actionTypes.Delete].includes(type)){
        const { entity } = action.payload;
        state[type].entity = entity;
        if([actionTypes.Edit].includes(type)){
          state[type].validationErrors = [];
        }
      }

      if([actionTypes.List,actionTypes.LocationFilterList, actionTypes.LocationFormList, actionTypes.UserFilter].includes(type)){
        const { entities } = action.payload;
        state[type].entities = entities;
        if([actionTypes.List].includes(type)){
          if(action.payload.meta){
            const { meta: { pagination: { total, pages } } } = action.payload;
            state[type].meta.pagination.total = total;
            state[type].meta.pagination.pages = pages;
          }
        }
      }

    },
  }
});
