import {instanceOf} from "prop-types";

export const HTTP_OK = 200
export const HTTP_CREATED  = 201
export const HTTP_UPDATED  = 202
export const HTTP_DELETED  = 204
export const HTTP_FOUND  = 302
export const HTTP_BAD_REQUEST  = 400
export const HTTP_UNAUTHORIZED  = 401
export const HTTP_PAYMENT_REQUIRED  = 402
export const HTTP_PERMISSION_DENIED  = 403
export const HTTP_NOT_FOUND  = 404
export const HTTP_NOT_ACCEPTABLE  = 406
export const HTTP_INVALID_ENTITY  = 422
export const HTTP_INTERNAL_SERVER_ERROR  = 500
export const axiosFormDataConfigs = {
  headers: {
    'accept': 'application/json',
    'Content-Type': `multipart/form-data;`,
  }
}
export const getFormData = (entity, files = {}) => {
  let formData = new FormData();
  formData.append("object", JSON.stringify(entity));

  // console.log(files)

  switch (true) {
    case files instanceof File :
      formData.append(`files[]`, files);
      break;
    case files === null : // must be null to be deleted
      formData.append(`files[]`, null);
      break;
    case Object.keys(files).length :
      formData.append(`files[]`, files);
  }


  // if(files instanceof File){
  //   //single file
  // } else {
  //   Object.keys(files).forEach((key)=>{
  //     if(Array.isArray(files[key])){
  //       files[key].forEach(file=>{
  //         formData.append(`files[${key}][]`, file);
  //       })
  //     }
  //   })
  // }


  return formData;
}