import faker from "faker";
import {API_UPLOAD_PATH} from "../../../../_emywork/helpers/UIHelper";

export const CONTEXT = "emy_products";
export const UPLOAD_PATH = `${API_UPLOAD_PATH}/${CONTEXT}`

export const CONTEXT_TITLE = {
  singular: "EMY_PRODUCT",
  plural: "EMY_PRODUCTS",
}
export const defaultEntity = process.env.NODE_ENV === "development" ?
  {
    "title": faker.lorem.words(),
    "description": faker.lorem.paragraphs(),
    "location_id": undefined,
    "function_id": undefined,
    "subcategories": [],
    "images": [],

    "video_url": faker.internet.url(),
    "pdf_url": faker.internet.url(),
    "website": faker.internet.url(),
    "phone": faker.phone.phoneNumber(),
    "phone_pro": faker.phone.phoneNumber(),
    "address": faker.address.streetAddress()

  } :
  {
    "title": '',
    "description": '',
    "price": 0,
    "delivery_time": 0,
    "response_time": 0,
    "location_id": undefined,
    "subcategories": [],
    "images": [],

    "video_url": undefined,
    "pdf_url": undefined,
    "website": undefined,
    "phone": undefined,
    "phone_pro": undefined,
    "function_id": undefined,
  }