import {createSlice} from "@reduxjs/toolkit";
import {AEMetadata} from "../../../../_emywork/AEPagination";

export const actionTypes = {
  List:"list",
  Delete:"deleteDialog",
  Edit:"editForm",
  Details:"Details",
}

export const defaultEntity = process.env.NODE_ENV === "development" ?
  {
    "name": 'team example',
    "phone": '0555',
    "email": 'contact@example.com',
    "note": 'fzezf',
    "user_id": undefined
  } :
  {
    "name": '',
    "phone": '',
    "email": '',
    "note": '',
    "user_id": undefined
  }

export const slice = createSlice({
  name: 'contacts',
  initialState: {
    [actionTypes.List]:{
      entities: [],
      error: null,
      isLoading: false,
      meta: new AEMetadata({perPage:10}, {}, {},['*']),
    },

    [actionTypes.Delete]:{
      entity: defaultEntity,
      error: null,
      isLoading: false,
    },

    [actionTypes.Edit]:{
      entity: defaultEntity,
      validationErrors: [],
      isLoading: false,
      error: null,
    },

    [actionTypes.Details]:{
      entity: defaultEntity,
      validationErrors: [],
      isLoading: false,
      error: null,
    },

  },
  reducers: {
    startCall: (state, action) => {
      const { type, meta } = action.payload;
      if(!type in actionTypes) return;

      state[type].error = null;
      state[type].isLoading = true;

      if([actionTypes.Edit, actionTypes.Details].includes(type)){
        state[type].validationErrors = [];
      }

      // if([actionTypes.List].includes(type)){
      //   state[type].meta = meta;
      // }
    },
    endCall: (state, action) => {
      const { error, type, entity } = action.payload;

      if(!type in actionTypes) return;

      state[type].error = error;
      state[type].isLoading = false;

      if([actionTypes.Edit, actionTypes.Details].includes(type)){
        state[type].validationErrors = [];
      }


    },
    catchError: (state, action) => {
      const { data, type } = action.payload;
      if(!type in actionTypes) return;

      state[type].error = true;
      state[type].isLoading = false;

      if([actionTypes.Edit, actionTypes.Details].includes(type)){
        state[type].validationErrors = data;
      }


    },
    fetched: (state, action) => {
      const { type } = action.payload;
      if(!type in actionTypes) return;

      state[type].error = null;
      state[type].isLoading = false;

      if([actionTypes.Edit, actionTypes.Delete, actionTypes.Details].includes(type)){
        const { entity } = action.payload;
        state[type].entity = entity;
        if([actionTypes.Edit, actionTypes.Details].includes(type)){
          state[type].validationErrors = [];
        }
      }

      if([actionTypes.List].includes(type)){
        const { entities } = action.payload;
        state[type].entities = entities;
        if([actionTypes.List].includes(type)){
          if(action.payload.meta){
            const { meta: { pagination: { total, pages } } } = action.payload;
            state[type].meta.pagination.total = total;
            state[type].meta.pagination.pages = pages;
          }
        }
      }

    },
  }
});
