import {createSlice} from "@reduxjs/toolkit";

export const actionTypes = {
  Users: "users",
  contacts: "contacts",
  teams: "teams",
  emyProjects: "emyProjects",
  emyServices: "emyServices",
  emyProducts: "emyProducts",
  notifications: "notifications",
  wallet: "wallet",
}

export const defaultStatisticsUsers = {
  total_users: 0,
  total_providers: 0,
  total_clients: 0,
}
export const defaultStatisticsContacts = {
  total_my_contacts: 0
}
export const defaultStatisticsTeams = {
  total_my_teams: 0
}
export const defaultStatisticsEmyProjects = {
  "total": 0,
  "my_total": 0,
  "cancelled": 0,
  "completed": 0,
  "on_going": 0
}
export const defaultStatisticsEmyServices = {
  total: 0,
  my_total: 0
}
export const defaultStatisticsEmyProducts = {
  total: 0,
  my_total: 0
}
export const defaultStatisticsNotifications = {
  total: 0
}
export const defaultStatisticsWallet = {
  balance: 0,
  transactions: [],
  transfers: [],
}

export const slice = createSlice({
  name: 'statistics',
  initialState: {
    [actionTypes.Users]: defaultStatisticsUsers,
    [actionTypes.contacts]: defaultStatisticsContacts,
    [actionTypes.teams]: defaultStatisticsTeams,
    [actionTypes.emyProjects]: defaultStatisticsEmyProjects,
    [actionTypes.emyServices]: defaultStatisticsEmyServices,
    [actionTypes.emyProducts]: defaultStatisticsEmyProducts,
    [actionTypes.notifications]: defaultStatisticsNotifications,
    [actionTypes.wallet]: defaultStatisticsWallet,
  },
  reducers: {
    fetched: (state, action) => {
      const { type, value } = action.payload;
      if(!type in actionTypes) return;

      state[type] = value;
    },
  }
});
