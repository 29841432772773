import faker from "faker";
import {API_UPLOAD_PATH} from "../../../../_emywork/helpers/UIHelper";

export const CONTEXT = "users";
export const UPLOAD_PATH = `${API_UPLOAD_PATH}/${CONTEXT}`

export const CONTEXT_TITLE = {
  singular: "USER",
  plural: "USERS",
}
export const defaultEntity = process.env.NODE_ENV === "development" ?
  {
    "username": "",
    "email": "",
    "phone": "",
    "roles": [],
    "commercial_name": "",
    "about": '',
    "badge": undefined,
    "legal_form_id": null,
    "activity_area_id": null,
    "location_id": null,
    "function_id": null,

    "skills": [],
    "experiences": [],
    "educations": [],
    "awards": [],
    "feedbacks": [],
    "emy_projects": [],
    "emy_products": [],
    "emy_services": [],
  } :
  {
    "username": "",
    "email": "",
    "phone": "",
    "roles": [],
    "commercial_name": "Elfannir Abderrahmane",
    "email_verified_at": "",
    "email_verify_token": null,
    "about": '',
    "badge": null,
    "legal_form_id": null,
    "activity_area_id": null,
    "location_id": null,
    "function_id": null,

    "skills": [],
    "experiences": [],
    "educations": [],
    "awards": [],
    "feedbacks": [],
    "emy_projects": [],
    "emy_products": [],
    "emy_services": [],
  }