import {createSlice} from "@reduxjs/toolkit";
import {AEMetadata} from "../../../../_emywork/AEPagination";
import {getConfig} from "../../../../_metronic/i18n";
import {localField} from "../../../../_emywork/helpers/UIHelper";
import faker from "faker";
export const actionTypes = {
  List:"list",
  Delete:"deleteDialog",
  Edit:"editForm",
  Details:"details",
  UserList:"UserList",
  HireModalList:"HireModalList",
}


export const defaultEntity = process.env.NODE_ENV === "development" ?
  {
    "title": faker.name.jobTitle(),
    "description": faker.lorem.text().substring(0, 250),
    "price": 0,
    "hours_per_week": 0,
    "duration": 0,
    "is_private": 0,
    "quotes_end_at": undefined,
    "category_id": undefined,
    "subcategory_id": undefined,
    "payment_term_id": undefined,
    "project_type_id": undefined,
    "location_id": undefined,
    "user_id": undefined,
    "skills":[],
    "attachments": []
  } :
  {
    "title": "",
    "file_name": null,
    "description": "",
    "price": 0,
    "hours_per_week": 0,
    "duration": 0,
    "quotes_end_at": undefined,
    "category_id": undefined,
    "subcategory_id": undefined,
    "payment_term_id": undefined,
    "project_type_id": undefined,
    "location_id": undefined,
    "user_id": undefined,
    "skills":[],
    "attachments": []
  }

const {selectedLang} = getConfig()



export const slice = createSlice({
  name: 'emy_projects',
  initialState: {
    [actionTypes.List]:{
      entities: [],
      error: null,
      isLoading: false,
      meta: new AEMetadata(
        {},
        { field:`name_${selectedLang}` },
        {},
        [
          'id', `slug`, `title`, `file_name`, `description`, `enabled`, `price`, `hours_per_week`, `duration`, 'user_id',
          "is_saved",
          "skills",`proposals.*.id`, `proposals.*.created_by`,
          "payment_term._key", `payment_term.${localField()}`,
          "created_by",`created_by.legal_form.${localField()}`,
          "category.id",`category.${localField()}`,
          "subcategory.id",`subcategory.${localField()}`,
          "location", `location.country.${localField()}`,
          "project_type",
        ]
      ),
    },

    [actionTypes.HireModalList]:{
      entities: [],
      error: null,
      isLoading: false,
      meta: new AEMetadata(
        {},
        { field:`name_${selectedLang}` },
        {},
        [
          'id', `slug`, `title`, `file_name`, `enabled`, 'user_id',
          "is_saved",
          "invites",
          // "payment_term._key", `payment_term.${localField()}`,
          // "created_by",`created_by.legal_form.${localField()}`,
          "category.id",`category.${localField()}`,
          "subcategory.id",`subcategory.${localField()}`,
          // "location", `location.country.${localField()}`,
          // "project_type",
        ]
      ),
    },
    [actionTypes.Delete]:{
      entity: defaultEntity,
      error: null,
      isLoading: false,
    },

    [actionTypes.Edit]:{
      entity: defaultEntity,
      validationErrors: [],
      isLoading: false,
      error: null,
    },
    [actionTypes.Details]:{
      entity: defaultEntity,
      isLoading: false,
      error: null,
    },

    [actionTypes.UserList]:{
      entities: [],
      error: null,
      isLoading: false,
      meta: new AEMetadata(
        {},
        { field:`name_${selectedLang}` },
        { },
        [
          'id', `slug`, `title`, `file_name`, `description`, `enabled`, `price`, `hours_per_week`, `duration`, 'user_id',
          "is_saved",
          "skills",`proposals.*.id`, `proposals.*.created_by`,
          "payment_term._key", `payment_term.${localField()}`,
          "created_by",`created_by.legal_form.${localField()}`,
          "category.id",`category.${localField()}`,
          "subcategory.id",`subcategory.${localField()}`,
          "location", `location.country.${localField()}`,
          "project_type",
        ]
      ),
    },
  },
  reducers: {
    startCall: (state, action) => {
      const { type } = action.payload;
      if(!type in actionTypes) return;

      // console.log(state[type], state, type)
      state[type].error = null;
      state[type].isLoading = true;

      if([actionTypes.Edit].includes(type)){
        state[type].validationErrors = [];
      }
    },
    endCall: (state, action) => {
      const { error, type, entity } = action.payload;

      if(!type in actionTypes) return;

      state[type].error = error;
      state[type].isLoading = false;

      if([actionTypes.Edit].includes(type)){
        state[type].validationErrors = [];
      }
    },
    catchError: (state, action) => {
      const { data, type } = action.payload;
      if(!type in actionTypes) return;

      state[type].error = true;
      state[type].isLoading = false;

      if([actionTypes.Edit].includes(type)){
        state[type].validationErrors = data;
      }
    },
    fetched: (state, action) => {
      const { type } = action.payload;
      if(!type in actionTypes) return;
      state[type].error = null;
      state[type].isLoading = false;

      if([actionTypes.Edit, actionTypes.Delete, actionTypes.Details].includes(type)){
        const { entity } = action.payload;
        state[type].entity = entity;
        if([actionTypes.Edit].includes(type)){
          state[type].validationErrors = [];
        }
      }
      if([actionTypes.List, actionTypes.UserList, actionTypes.HireModalList].includes(type)){
        const { entities, meta } = action.payload;
        if(meta){
          const {pagination:{total,pages}} = meta;
          state[type].meta.pagination.total = total;
          state[type].meta.pagination.pages = pages;
        }
        state[type].entities = entities;
      }
    },
    //
    addComment:(state, action)=>{
      const { type, comment } = action.payload;
      if(!type in actionTypes) return;

      if([actionTypes.Details].includes(type)){
        state[type].entity.comments.push(comment)
      }
    }
  }
});